<template>
  <div class="container">
    <div class="content">
      <h3 class="title">
        PWN Terms and Conditions
      </h3>
      <h4>1. PWN Platform</h4>

      <p>
        PWN Platform is a collection of tools and interfaces available under the "PWN.xyz" domain and its subdomains. PWN primarily represents an interface to underlying smart contracts which are publically accessible on the Ethereum blockchain.
      </p>
      <p>
        PWN only provides a limited and incomplete interface to the smart contracts and PWN is also not the sole provider of such smart contract interface.  PWN is completely non custodial and does not handle nor store any information that could identify users of the interface.
      </p>
      <p>
        Provision of access to the various interfaces available under the "pwn.xyz" domain and its two subdomains: "app.pwn.xyz" and "api.pwn.xyz" will hereby be referred to as a "Service". Non other components can be assumed to be provided by PWN.
      </p>
      <h4>2. The User</h4>
      <p>
        You, as a user of the Service, confirm that you have read these "Terms of Use" in full, that you acknowledge them and that you are in complete agreement and compliance with them.
      </p>
      <p>
        By using the Service you inherently agree to use the Service according to the Law in all applicable jurisdictions and acknowledge that you yourself bear all the legal obligations associated with using the Service.
      </p>
      <p>
        You also understand that PWN AG is only a provider of an interface which is accessible also through alternative means - making you independent of the Service.
      </p>
      <p>
        You commit to use the Service only in an ethical way, not taking an unfair advantage of other users.
      </p>
      <h4>3. Don't trust, verify</h4>
      <p>
        The Service operates under the "Don't trust verify" model. That means that all components which may be touching any of the crypto assets belonging to the User shall be publically visible and testable in full scope - the smart contracts shall be validated by the user prior to the use of the Service.
      </p>
      <p>
        It is the responsibility of the User to validate correctness of any on-chain interactions the user will be signing with their private keys using a wallet of their own choice.
      </p>
      <h4>4. Acknowledging the risks associated with using the service</h4>
      <p>
        You acknowledge the fact that all the Service, the 3rd party services used for provision of the Service and the associated technologies such as smart contracts or Ethereum blockchain are highly experimental and may be unstable or disfunctional.
      </p>
      <p>
        PWN doesn't provide any assurance for availability of the Service.
      </p>
      <p>
        PWN is not responsible for any losses or costs which may be caused by malfunction of the Service, its dependencies or malfunction of the Ethereum smart contracts.
      </p>
      <p>
        PWN is at no point becoming a custodian of any of the assets that may be used in relation with the Service and therefore PWN can't cover any losses or perform any recovery of those assets.
      </p>
      <h4>5. Licenses & IP</h4>
      <p>
        PWN AG reserves all rights for all underlying software, assets and data available via pwn.xyz, app.pwn.xyz and api.pwn.xyz - except for aggregate third party content, third party data streams, dependency software used.
      </p>
      <h4>6. Third Party Services and Links</h4>
      <p>
        The PWN websites and documentation may include links to third party content or third party services. PWN does not have any right nor control over the content served through these sources. All of the third party references should only be used as an additional information which isn't integral for using the Service.
      </p>
      <h4>7. About Us</h4>
      <p>
        PWN DAO Foundation (Nadace PWN DAO) is a Czech non-profit under the license number: 174 41 382, located on Korunni 2569/108, Vinohrady, 101 00, Prague 10.
        PWN DAO Foundation provides the platform as an interface and does not directly benefit from the operation of the platform.
      </p>
      <p>
        In case of any questions or inquiries, please contact us at <a
          class="link"
          href="mailto:legal@pwn.xyz"
          target="_blank">legal@pwn.xyz</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
}
</script>

<style scoped>
.container {
  min-width: 100%;
  justify-content: center;
  display: flex;
}

.title {
  font-family: var(--font-family-autoscape);
}

.content {
  padding: var(--s3);
  color: var(--white);
  max-width: 900px;
  font-family: var(--font-family-oxygen-mono);
}

p {
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 21px !important;
  font-style: normal;
  font-weight: 400;
  margin: 3.9rem 0 1rem !important;
  font-family: var(--font-family-autoscape);
}
</style>
