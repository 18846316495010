<template>
  <video-background
    src="/nft.mp4"
    class="splash-video">
    <div
      id="content"
      class="container">
      <div class="row">
        <div class="offset-by-seven five columns">
          <div class="logo">
            <img src="/PWN_logo.svg">
          </div>
        </div>
      </div>
      <div class="cta-wrap">
        <div class="intro-text title">
          The Most Universal Lending Protocol
        </div>
        <p class="subtitle">
          Fund crypto-backed loans today
        </p>
      </div>
    </div>
    <Footer/>
  </video-background>
</template>

<script>
import Footer from '@/components/Splash/Footer.vue'

export default {
  name: 'Video',
  components: { Footer },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  margin-top: 32vh;
  width: 100%;
  padding-right: 128px;
}

.cta-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.launch-btn-and-subtitle {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.launch-btn {
  align-self: flex-end;
}

.flex-to-right {
  display: flex;
  justify-content: flex-end;
}

.logo {
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  margin-bottom: 35px;
}

img {
  width: 150px;
}

.intro-text {
  font-size: 32px;
  width: 625px;
  color: white;
  text-align: right;
  line-height: 42px;
  opacity: 0.8;
}

.subtitle {
  font-size: 20px;
  line-height: 28px;
  color: white;
  margin-top: 21px;
  margin-bottom: 55px;
}

h1 {
  color: var(--col-text-accent);
  font-size: 2.2em;
  text-align: right;
  margin-top: -10px;
}

/*/////// Mobile ///////*/

@media (max-width: 1100px) {
  .logo {
    display: none;
  }

  .title-with-bg {
    background: #05cfb94d;
    line-height: -12;
    height: 35px;
    width: 234px;
    z-index: 10;
    margin-top: 6px;
    position: absolute;
  }

  .container {
    width: 100%;
    margin: 16vh 0;
    padding-right: 0;
    max-width: 550px;
  }

  .cta-wrap {
    display: flex;
    flex-direction: column;
    margin: 0 25px;
    align-items: flex-start;
    max-width: 450px;
  }

  .intro-text {
    font-size: 25px;
    line-height: 45px;
    width: 100%;
    text-align: left;
  }

  .subtitle {
    font-size: 15px;
    line-height: 28px;
    margin-top: 42px;
    margin-bottom: 56px;
    width: 100%;
  }
}

.splash-video {
  max-height: 100vh;
  height: 100vh;
  position: fixed;
}
</style>
