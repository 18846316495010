import { ref } from 'vue'
import { PoapEvent } from '@/components/Poap/poap'

const activePoap = ref<PoapEvent>()

export default function usePoap() {
  const fetchActivePoap = async () => {
    const query = `{
      poapClaimCollection(order: [eventDate_DESC], limit: 1) {
        items {
          poapWebsiteName
          eventDate
        }
      }
    }`

    const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${
      process.env.VUE_APP_CONTENTFUL_SPACE_ID
    }`

    const fetchOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
      }),
    }

    try {
      const response = await fetch(fetchUrl, fetchOptions)
      const JSONResponse = await response.json()

      activePoap.value = JSONResponse.data.poapClaimCollection.items[0]
    } catch (error) {
      throw new Error('Could not receive the data from Contentful!')
    }
  }

  return {
    activePoap,
    fetchActivePoap,
  }
}
