import EventsVue from '@/Events.vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../Home.vue'
import TermsAndConditionsVue from '../TermsAndConditions.vue'
import PrivacyPolicy from '../PrivacyPolicy.vue'
import WaitlistVue from '../Waitlist.vue'
import PageNotFound from '@/components/PageNotFound.vue'
import PoapPage from '@/PoapPage.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home,
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: TermsAndConditionsVue,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/waitlist',
    name: 'waitlist',
    component: WaitlistVue,
  },
  {
    path: '/events',
    name: 'events',
    component: EventsVue,
  },
  {
    path: '/poap',
    name: 'poap',
    component: PoapPage,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'unknown',
    component: PageNotFound,
    meta: {
      title: 'Page not found',
    },
  },
]

export default createRouter({
  history: createWebHistory(),
  routes,
})
