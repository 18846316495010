<template>
  <a
    class="custom-link"
    href="https://app.pwn.xyz"
    target="_blank">
    <div class="primary-btn responsive">Launch App</div>
  </a>
</template>

<style scoped>
.custom-link {
  display: flex;
  white-space: nowrap;
}

/*/////// Mobile ///////*/

@media (max-width: 1100px) {
  .responsive {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    text-align: center;
  }

  .custom-link {
    width: 100%;
    justify-content: center;
  }
}
</style>
