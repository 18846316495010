import { createClient, Entry, EntryCollection } from 'contentful'
import { ref } from 'vue'
import { CryptoEvent } from './events'

const client = createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE_ID || '',
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN || '',
})

const events = ref<EntryCollection<CryptoEvent>>()

const selectedEvent = ref<number>(-1)

export default function useEvents() {
  const getEvents = async () => {
    events.value = await client.getEntries({ content_type: 'events' })
    events.value.items.sort((a: Entry<CryptoEvent>, b: Entry<CryptoEvent>) => (a.fields.eventId > b.fields.eventId) ? 1 : -1)
  }

  return {
    events,
    getEvents,
    selectedEvent,
  }
}
