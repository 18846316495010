export const moralisServerUrl = 'https://u3mhdofzmcp5.usemoralis.com:2053/server'
export const moralisAppId = 'hUF7p36siRh2zL54XI4zYaDi4Yqa3DOkteoXXF4k'
export const accessPoapIds = ['17152', '23232', '43018']

export const linkTo = {
  app: 'https://app.pwn.xyz',
  assetFaucet: 'https://faucet.pwn.xyz',
  contract: 'https://polygonscan.com/address/0xDBdb041842407c109F65b23eA86D99c1E0D94522',
  instagram: 'https://www.instagram.com/pwndao/',
  linkedIn: 'https://www.linkedin.com/company/pwndao/',
  maticFaucet: 'http://twitter.com/intent/tweet?text=Hey%20%40pwndao%2C%20please%20hit%20me%20with%20that%20free%20MATIC!%20%23NFTDay',
  twitter: 'https://twitter.com/pwndao',
  twitterWithDraft: 'http://twitter.com/intent/tweet?text=I%20just%20minted%20my%20very%20own%20plaNFT%20by%20completing%20a%20loan%20on%20%40pwndao.%20Try%20it%20yourself%20at%3A%20https%3A%2F%2Fpwn.xyz%2Fcollection',
  whitepaper: 'https://pwn.xyz/PWN-Whitepaper.pdf',
  youtube: 'https://www.youtube.com/@pwndaoxyz',

  // Extras in footer
  pwnSafe: 'https://pwnsafe.pwn.xyz',
  cryptonativeReport: 'https://cryptonative.pwn.xyz',
  blog: 'https://pwn.mirror.xyz/',

  // Resources in footer
  docs: 'https://docs.pwn.xyz',
  github: 'https://github.com/PWNFinance',
  audit: 'https://audit.pwn.xyz/',
  faq: 'https://faq.pwn.xyz/',

  // Community in footer
  email: 'mailto:contact@pwn.xyz',
  telegram: '',
  discord: 'https://discord.gg/aWghBQSdHv',
  careers: 'https://pwndao.notion.site/PWN-is-hiring-f5a49899369045e39f41fc7e4c7b5633',

}
