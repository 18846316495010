/**
 * Connect metamask wallet to our site.
 * @returns {Promise<string>} if successfully connected, this method returns connected user ethereum address
 */
// import {to} from "./utils";
import Moralis from 'moralis'

export async function connectWallet() {
  const user = await Moralis.authenticate({ signingMessage: 'Sign to connect to the PlaNFT app.' })
  const userAddress = user.get('ethAddress')
  return userAddress
}

export function hasEthereumWalletInstalled() {
  return typeof window.ethereum !== 'undefined'
}

export async function autoConnectWallet() {
  if (hasEthereumWalletInstalled()) {
    const lastConnectedUserAddress = await window.ethereum.request({ method: 'eth_accounts' })
    if (lastConnectedUserAddress?.length) return await connectWallet()
  }
}
