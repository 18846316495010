import { fetchRetry, to } from './utils'
import { accessPoapIds } from './constants'
import { FetchRetryParams } from 'fetch-retry-ts'

const BACKEND_API_URL = 'https://api.pwn.xyz/api/v1'

export async function joinWaitlist(userAddress: string) {
  const userVerificationSignature = await window.ethereum?.request({
    method: 'personal_sign',
    params: ['Sign this message to join PWN Waitlist!', userAddress],
  })

  const joinWaitlistUrl = `${BACKEND_API_URL}/waitlist/`
  const [error, joinWaitlistResponse] = await to(fetch(joinWaitlistUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ signature: userVerificationSignature, address: userAddress }),
  }).then(response => response.json()))

  return {
    userPoints: error ? null : joinWaitlistResponse.score,
    userRank: error ? null : joinWaitlistResponse.ranking,
    hasAccessPoap: error ? false : joinWaitlistResponse.has_access_poap,
  }
}

export async function fetchUserWaitlistAccount(userAddress: string) {
  const waitlistAccountUrl = `${BACKEND_API_URL}/waitlist/${userAddress}/`
  const [error, waitlistAccountResponse] = await to(fetch(waitlistAccountUrl).then(response => response.json()))

  return {
    userPoints: error ? null : waitlistAccountResponse.score,
    userRank: error ? null : waitlistAccountResponse.ranking,
    hasAccessPoap: error ? false : waitlistAccountResponse.has_access_poap,
  }
}

export async function fetchHasPoap(userAddress: string) {
  type UserPoapOwnershipResponse = { event: { id: number } }

  const url = `https://api.poap.xyz/actions/scan/${userAddress}`
  const poapApiRequestRetryOptions: FetchRetryParams = {
    retries: 5,
    retryDelay: 1200,
    retryOn: [502, 504],
  }

  const [error, userPoaps] = await to(fetchRetry(url, poapApiRequestRetryOptions).then(response => response.json())) as [Error, UserPoapOwnershipResponse[]]
  return (error || !userPoaps?.length) ? false : userPoaps.some((userPoap) => accessPoapIds.includes(String(userPoap?.event?.id)))
}
