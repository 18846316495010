<template>
  <div class="container">
    <div class="content">
      <h3 class="title">
        Privacy Notice of PWN
      </h3>

      <p>
        Last update: 1st of October 2023
      </p>

      <h4>1. General Information</h4>

      <p>
        This Privacy Notice describes how PWN AG (Grafenaustrasse 5, 6300 Zug, CHE-146.271.188) incorporated under the
        laws of Switzerland (also «we», «us» or «our») collects, protects, and uses your personal data when you use our
        website (in this context, the term «website» also includes our web portals or Apps) obtain products or services
        from us, interact with us in relation with a contract, communicate with us or otherwise deal with us. We explain
        what we do with your personal data, for what purposes and on what legal foundation we do so, and what rights you
        have on that basis. Any changes to our Privacy Policy will be posted to this page so users are always aware of
        the information we collect and how we use it. Accordingly, please refer back to this Privacy Policy frequently
        as it may change. In addition, we may inform you about the processing of your data separately, for example in
        consent forms, terms and conditions, additional privacy notices, forms and other notices. We use the word «data»
        here interchangeably with «personal data».
      </p>
      <ul>
        <li>
          <p>
            «Personal data» means any information relating to an identified or identifiable natural person («data
            subject»); an identifiable natural person is one who can be identified, directly or indirectly, in
            particular by reference to an identifier such as a name, an identification number, location data, an online
            identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic,
            cultural or social identity of that natural person; «sensitive personal data» is a subset of personal data
            and revealing e.g. racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade
            union membership, genetic data, biometric data, health data or data concerning a natural person's sex life
            or sexual orientation
          </p>
        </li>
        <li>
          <p>
            «Processing» means any operation or set of operations which is performed on personal data or on sets of
            personal data, whether or not by automated means, such as collection, recording, organization, structuring,
            storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination
            or otherwise making available, alignment or combination, restriction, erasure or destruction
          </p>
        </li>
      </ul>
      <p>
        If you provide information to us about any person other than yourself, your employees, counterparties, your
        advisers or your suppliers, you must ensure that the data is accurate and that they understand how their
        information will be used, and that they have given their permission for you to disclose it to us and for you to
        allow us, and our outsourced service providers, to use it.
        This Privacy Notice is aligned with the EU General Data Protection Regulation («GDPR») and the Swiss Data
        Protection Act («DPA»). However, the application of these laws depends on each individual case.
      </p>

      <h4>2. Categories of Data we Process</h4>
      <p>
        The processing of personal data is limited to data that is required to operate a functional website and for the
        provision of content, products and services. The processing of personal data of our users is based on the
        purposes agreed or on a legal basis. We only collect personal data that is necessary to implement and process
        our tasks and services or if you provide data voluntarily. Depending on the reason and purpose of the
        processing, we process different data about you:
      </p>
      <p>
        Technical Data
        When you use our website, or other online offerings, we collect the IP address of your terminal device and other
        technical data in order to ensure the functionality and security of these offerings. This data includes logs
        with records of the use of our systems. We generally keep technical data for 1 month. Technical data as such
        does not permit drawing conclusions about your identity. However, technical data may be linked with other
        categories of data (and potentially with your person) in relation with registrations, access controls or the
        performance of a contract.
      </p>

      <div
        v-tooltip="{content:'' +
                      '<ul>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            the IP address and information about the operating system of your device\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>name and URL of any visited page</p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>the date and time of access</p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>information, whether the access was successful (access status/http status code)</p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>amount of data transferred</p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>websites that are accessed via our website</p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>website form which any access takes place (so-called referrer URL)</p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>the type of browser that you use to access our online offerings</p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>name of your internet provider</p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            browser type and version used, and other information provided by the browser (such as geographical origin,\n'+
                      '            language setting, add-ons used, screen resolution, etc.)\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            logs that are created in our systems (e.g. the log of user logins to our website) and possibly the number of\n'+
                      '            clicks\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '      </ul>',
                    theme: 'info-tooltip', html: true
        }"
        class="link link-dropdown">
        Technical data includes:
      </div>

      <p>
        This may help us to provide an appropriate layout of the website or, for example, to display a sub-page for your region. We know through which provider you access our offerings (and therefore also the region) because of the IP address, but usually this does not tell us who you are. However, this changes for example when you register, because personal data can then be linked with technical data (for example, we can know the browser you use to access your profile through our website).
      </p>

      <p>
        Registration Data
      </p>

      <p>
        Some of our services may only be used with a log-in or a registration (e.g. competitions, newsletters, etc.). Such data will be kept for 12 months from the date of the use of the services ceases.
      </p>

      <div
        v-tooltip="{content:'' +
                      '<ul>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            Your wallet address\n\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>Any other information you provide when you access our website (for example user description)\n</p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>contact details when you subscribe to our newsletter</p>\n'+
                      '        </li>\n'+
                      '      </ul>',
                    theme: 'info-tooltip', html: true
        }"
        class="link link-dropdown">
        Registration data includes:
      </div>

      <p>
        Communication Data
      </p>

      <p>
        When you get in contact with us via contact form, e-mail, telephone, chat, or by letter or other means of communication, we collect the data exchanged between you and us, including your contact details and the metadata of the communication. If we have to confirm your identity, for example in relation to a request for information, a request for press access, etc. We collect data to identify you (for example a copy of an ID document). We generally keep this data for 12 months from the last exchange between us. This period may be longer where required for evidentiary purposes, to comply with legal or contractual requirements, or for technical reasons. E-mails in personal mailboxes and written correspondence are generally kept for at least 10 years. Chats are generally stored for 2 years.
      </p>

      <div
        v-tooltip="{content:'' +
                      '<ul>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            your name and contact details\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>Any other information you provide when you access our website (for example user description)\the means, place and time of communication and usually also its contents (i.e. the contents of e-mails, phone type, occupation, range of managed funds, letters, chats, etc.). This data may also include information about third parties.n</p>\n'+
                      '        </li>\n'+
                      '      </ul>',
                    theme: 'info-tooltip', html: true
        }"
        class="link link-dropdown">
        Communication data includes:
      </div>

      <p>
        Master data is the basic data that we need, in addition to contract data, for the performance of our contractual and other business relationships or for marketing and promotional purposes, such as name and contact details, and information about, for example, your role and function, your bank details, customer history, powers of attorney, signature authorizations and declarations of consent, and further data relating to fraud prevention and the combating of money laundering and terrorist financing, export restrictions, sanctions and embargoes. We process your master data if you are a customer or other business contact or work for one (for example as a contact person of the business partner), or because we wish to address you for our own purposes or for the purposes of a contractual partner (for example as part of marketing and advertising, for invitations to events, newsletters, etc.). We receive master data from you (for example when you make use of our services provided), from parties you work for, or from third parties such as contractual partners, associations and address brokers, and from public sources such as public registers or the internet (websites, social media, etc.) or from activity that is publicly visible and/or accessible on blockchains (e.g. Blockchain address, information regarding purchases, sales, or transfer of NFTs, which may then be associated with other data you have provided to us). We generally keep master data for 10 years from the last exchange between us but at least from the end of the contract. This period may be longer if required for evidentiary purposes, to comply with legal or contractual requirements, or for technical reasons. For contacts used only for marketing and advertising, the period is usually much shorter, usually no more than 2 years from the last contact.
      </p>

      <div
        v-tooltip="{content:'' +
                      '<p>Master data is not comprehensively collected for all contacts. Rather, the collection of master data depends on the individual case and purpose of the processing. In general, it may include:</p>' +
                      '<ul>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            your name\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            address\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            e-mail address\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            telephone number and other contact details\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            wallet address\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            date of birth\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            nationality\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            data about related persons\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            websites\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            social media profiles\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            photos and videos\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            copies of ID cards\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            details of your relationship with us (e.g. customer, supplier, visitor, service provider or service recipient, tc.)\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            details of interactions with you\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            reports\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            official documents (e.g. excerpts from the commercial register, permits)\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            payment information (e.g. bank details, account number and credit card data)\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            declarations of consent and opt-out information\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            as regards suppliers and partners, master data also includes information about the role or function in the company, qualifications and information about superiors, co-workers and information about interactions with these persons\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '      </ul>',
                    theme: 'info-tooltip', html: true
        }"
        class="link link-dropdown">
        Master Data Includes:
      </div>

      <p>
        Contract Data
      </p>

      <p>
        We collect contract data in relation with the conclusion or performance of a contract, e.g. information about the products and the services provided or to be provided, as well as data from the period leading up to the conclusion of a contract, information required or used for performing a contract, and information about feedback (e.g. complaints, feedback about satisfaction, etc.). We generally collect this data from you, from contractual partners and from third parties involved in the performance of the contract, but also from third-party sources (for example credit information providers) and from public sources. We generally keep this data for 10 years from the last contract activity but at least from the end of the contract. This period may be longer where necessary for evidentiary purposes, to comply with legal or contractual requirements, or for technical reasons.
      </p>

      <div
        v-tooltip="{content:'' +
                      '<p>Contract data includes</p>' +
                      '<ul>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            information about the conclusion of the contract, about your contracts, for example, the type and date of conclusion and its duration (including all information relating to our programs; e.g. such as grants programs, bounty programs, bug-bounty programs etc.)\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>the performance and administration of the contracts, for example information in relation with billing, customer service, technical assistance and the enforcement of contractual claims</p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>information about deficiencies, complaints and changes of a contract as well as customer satisfaction information that we may collect for example through surveys</p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>financial data, such as credit information (meaning information that allows to draw conclusions about the likelihood that receivables will be paid), information about reminders and debt collection</p>\n'+
                      '        </li>\n'+
                      '      </ul>',
                    theme: 'info-tooltip', html: true
        }"
        class="link link-dropdown">
        Contract data includes
      </div>

      <p>
        We receive this data partly from you (for example when you make payments), but also from credit agencies and debt collection companies and from public sources (for example a commercial register).
      </p>

      <p>
        Behavioural and preference data
      </p>

      <p>
        Depending on our relationship with you, we try to get to know you better and to tailor our products, services and offers to you. For this purpose, we collect and process data about your behaviour and preferences. We do so by evaluating information about your behaviour. Based on this data, we can for example determine the likelihood that you will use certain services or behave in a certain way. The data processed for this purpose is already known to us (for example where and when you use our services), or we collect it by recording your behaviour (for example how you navigate our website. We anonymize or delete this data when it is no longer relevant for the purposes pursued, which may be – depending on the nature of the data – between 2-3 weeks and 24 months (for product and service preferences). This period may be longer as for evidentiary purposes, to comply with legal or contractual requirements, or for technical reasons.
      </p>

      <div
        v-tooltip="{content:'' +
                      '<p>Contract data includes</p>' +
                      '<ul>\n'+
                      '        <li>\n'+
                      '          <p>\n'+
                      '            information about certain actions, such as your response to electronic communications (for example if and when you have opened an e-mail) or your location, as well as your interaction with our social media profiles and your participation in sweepstakes, competitions and similar.\n'+
                      '          </p>\n'+
                      '        </li>\n'+
                      '        <li>\n'+
                      '          <p>For example, we may collect your location data wirelessly through unique codes that your cell phone emits or when you use our website. We will use signage at the relevant locations to tell you about the collection of such anonymous motion profiles, and we will only create personalized motion profiles with your consent</p>\n'+
                      '        </li>\n'+
                      '      </ul>',
                    theme: 'info-tooltip', html: true
        }"
        class="link link-dropdown">
        Behavioural data includes
      </div>

      <p>
        Preference data provides information on your needs, which products or services you might be interested in. We obtain this information by analysing existing data, such as behavioural data, so that we get to know you better and can better tailor our products and services to you. Such data also contributes to a general improvement of our products and services. We combine this data with other data we obtain from third parties, such as address broker, administrative office and publicly available sources (e.g. the internet), such as information about your household size, income bracket and purchasing power, shopping behaviour, contact data of relatives, and anonymous information from statistical offices.
      </p>

      <p>
        Behavioural and preference data may be analysed on a personally identifiable basis (for example to show you personalized advertising), but also on a non-identifiable basis (for example for market research or product development). Behavioural and preference data may also be combined with other data (for example, motion data may be used for contact tracing as part of a health protection concept).
      </p>

      <div
        v-tooltip="{content: '<p>We also collect data from you in other situations. For example, data that may relate to you (such as files, evidence, etc.) is processed in relation with administrative or judicial proceedings. We may also collect data for health protection (for example as part of health protection concepts). We may obtain or create photos, videos and sound recordings in which you may be identifiable (for example at events, with security cameras, etc.). We may also collect data about who enters certain buildings, and when or who has access rights (including in relation with access controls, based on registration data or lists of visitors, etc.), who participates in events or campaigns, e.g. competitions and who uses our infrastructure and systems and when. The retention period for this data depends on the processing purpose and is limited to what is necessary. This ranges from one or two days for many of the security cameras, to usually a few weeks in case of data for contact tracing and visitor data that is usually kept for 3 months, to several years or longer for reports about events with images.</p>',
                    theme: 'info-tooltip', html: true
        }"
        class="link link-dropdown">
        Other data
      </div>

      <p>
        Much of the data set out in this Section is provided to us by you, e.g. through forms, in relation with communication with us, in relation with contracts, when you use the website, etc. You are not obliged or required to disclose data to us except in individual cases, for example within the framework of binding health protection concepts (legal obligations). If you wish to enter into contracts with us or use our services, you must also provide us with certain data, in particular master data, contract data and registration data, as part of your contractual obligation under the relevant contract. When using our website, the processing of technical data cannot be avoided. If you wish to gain access to certain systems or buildings, you must also provide us with registration data. However, in the case of behavioural and preference data, you have the option of objecting or not giving consent.
      </p>

      <p>
        We provide certain services to you only if you provide us with registration data, because we or our contractual partners wish to know who uses our services or has accepted an invitation to an event, because it is a technical requirement or because we wish to communicate with you. If you or the person you represent (for example your employer) wishes to enter into or perform a contract with us, we must collect master data, contract data and communication data from you, and we process technical data if you wish to use our website or other electronic offerings for this purpose. If you do not provide us with the data necessary for the conclusion and performance of the contract, you should expect that we may refuse to conclude the contract, that you may commit a breach of contract or that we will not perform the contract. Similarly, we can only submit a response to a request from you if we process communication data and – if you communicate with us online – possibly also technical data. Also, the use of our website is not possible without us receiving technical data.
      </p>

      <p>
        As far as it is not unlawful we also collect data from public sources (for example debt collection registers, land registers, commercial registers, the media, or the internet including social media) or receive data from other companies within our group, from public authorities and from other third parties (such as credit agencies, address brokers, associations, contractual partners, internet analytics services, etc.).
      </p>

      <p>
        The categories of personal data that we receive about you from third parties include, in particular, information from public registers, information that we receive in relation with administrative and legal proceedings, information in relation with your professional functions and activities (so that we can, for example, conclude and process transactions with your employer with your assistance), information about you in correspondence and meetings with third parties, credit information (where we conduct business with you in a personal capacity), information about you that persons related to you (family, advisors, legal representatives, etc.) share with us so that we can conclude or perform contracts with you or involving you (for example references, your delivery address, powers of attorney, information about compliance with legal requirements such as those relating to fraud prevention and the combating of money laundering and terrorist financing and Know Your Customer (KYC) data, Know Your Business (KYB) data, sanctions, export restrictions, information from banks, insurance companies, sales and other contractual partners of us about your use or provision of services (for example payments, purchases, etc.), information from the media and the internet about the use or provision of services by you (for example payments made, purchases made, etc.), information from the media and the internet about you (where appropriate in a specific case, e.g. in the context of an application, marketing/sales, press review, etc., your address and potentially interests and other socio-demographic data (especially for marketing and research purposes) and data in relation with the use of third-party websites and online offerings where such use can be linked to you.
      </p>

      <h4>
        3. Purposes of the Processing
      </h4>

      <p>
        We process your data for the purposes explained below.
      </p>

      <p>
        Communication
      </p>

      <p>
        We process your data for communication purposes, in order to communicate with you, in particular, when you contact us in order, to respond to your queries or when you exercise your rights. For this purpose, we use in particular communication data, master data and registration data to enable us to communicate with you and provide our services or respond to requests. We keep this data to document our communication with you, for training purposes and quality assurance.
      </p>

      <p>
        Performance of a Contract
      </p>

      <p>
        We process your data for entering into a contract with you, perform and administer it. In particular, we process communication data, master data, registration data and contract data about you. This might include data about third parties, e.g. if you order products or services for the benefit of a third party. This also includes data about potential customers, that we receive from communication with you, on a trade fair or any other business event. As regards the conclusion of a contact, we use this data to assess your creditworthiness and to open up a business relationship with you. Administering and performing the contract with you might involve third parties, such as logistic companies, advertising service providers, banks, insurance companies or credit information providers in order to provide our products and services to you.
      </p>

      <p>
        Marketing and Relationship Management
      </p>

      <p>
        We process your data for marketing and relationship management purposes. For example, we send personalized newsletters for products and services from us and. Marketing and relationship management might include contacting you via e-mail, telephone or other channels for which we have contact information from you. We and, if applicable, selected third parties, only display personalized content or advertising based on your usage behaviour or send e-mails for marketing purposes (e.g. newsletter) if and to the extent you give your consent to us if required under applicable law. You can object to such marketing activities or withdraw your consent at any time.
      </p>

      <p>
        Product/Service Improvement and Innovation
      </p>

      <p>
        We process your data for market research and to improve our products and services (including our website).
      </p>

      <p>
        Safety or Security Reasons
      </p>

      <p>
        We process your data to protect our IT and other infrastructure (e.g. buildings). For example, we process data for monitoring, analysis and testing of our networks and IT infrastructures including access controls. We might also use surveillance systems, e.g. cameras for security purposes. In such a case, we will inform you at the relevant locations separately.
      </p>

      <p>
        Compliance with Law
      </p>

      <p>
        We process your data to comply with legal requirements, e.g. health security concepts, money laundering and terrorist financing, tax obligations etc. and we might have to request further information from you to comply with such requirements (“Know Your Customer”, “KYC”; “Know Your Business”, “KYB”) or as otherwise required by law and legal authorities.
      </p>

      <p>
        Risk Management, Corporate Governance and Business Development
      </p>

      <p>
        We process your data as part of our risk management and corporate government in order to protect us from criminal or abusive activity. As part of our business development, we might sell businesses, parts of businesses or companies to others or acquire them from others or enter into partnerships and this might result in the exchange and processing of data based on your consent, if necessary.
      </p>

      <h4>
        4. Legal Basis for Processing your Data
      </h4>

      <p>
        Your Consent
      </p>

      <p>
        Where we asked for your consent (e.g., for receiving newsletters and for personalized content or advertising based on your usage behaviour or for processing sensitive data), we process your data based on such consent. You may withdraw your consent at any time with effect for the future by providing us written notice (e-mail sufficient). Withdrawal of your consent does not affect the lawfulness of the processing that we have carried out prior to your withdrawal, nor does it affect the processing of your data based on other processing grounds.
      </p>

      <p>
        Where we did not ask for your consent, we process your data on other legal grounds, such as
      </p>

      <ul>
        <li>
          a contractual obligation
        </li>
        <li>
          a legal obligation
        </li>
        <li>
          a vital interest of the data subject or of another natural person
        </li>
        <li>
          a legitimate interest, which includes compliance with applicable law and the marketing of our products and services, the interest in better understanding our markets and in managing and further developing our company, including its operations, safely and efficiently
        </li>
      </ul>

      <h4>
        5. Profiling and Automated Decision Taking
      </h4>

      <p>
        We might analyse aspects of your individual's personality, behaviour, interest and habits to make predictions or decisions to perform statistical analysis or to prevent misuse and security risks. This analysis identifies correlations between different behaviors and characteristics to create profiles for individuals. For example, we may use profiling to determine which products or services you might be interested in. We may also use profiling to assess your creditworthiness. We do not use profiling that can produce legal effects concerning you or similarly significantly affect you without human review.
      </p>

      <p>
        In certain circumstances, automated decision taking might be necessary for reasons of efficiency and consistency. In such cases, we will inform you accordingly and take the measures required by applicable law.
      </p>

      <h4>
        6. Onchain Data
      </h4>

      <p>
        By using our blockchain-based services you acknowledge that your wallet address and related transactions, which are considered personal data if relating to an identified or identifiable natural person, are permanently and publicly stored on the blockchain, publicly available to anyone and neither we, nor any third party, has any power to delete such data published by its users to the blockchain. If you want to ensure that your privacy rights are not affected in any way, you should not transact on blockchains as certain rights may not be fully available or exercisable by you or us due to the technological infrastructure of the blockchain.
      </p>

      <p>
        You hereby release and indemnify us of any data privacy liability associated with data that you published to the blockchain by using our services.
      </p>

      <h4>
        7. Disclosure of Data to Third Parties and Social Plug-ins
      </h4>

      <p>
        In order to perform our contracts, fulfill our legal obligations, protect our legitimate interest and the other purposes and legal grounds set out above, we may disclose your data to third parties, in particular to the following categories of recipients:
      </p>

      <p>
        Group companies
      </p>

      <p>
        You hereby release and indemnify us of any data privacy liability associated with data that you published to the blockchain by using our services.
      </p>

      <p>
        Offerings of Third Parties
      </p>

      <p>
        Our Website may contain third-party offerings. If you click on such an offer, we will transfer data to the respective third party to the extent necessary (e.g., the information that you found this offer on our website or app and, if applicable, further information that you provided for this purpose on our website or app). We have no control over, do not review and cannot be responsible for these third-party websites or their content. Please be aware that the terms of this Privacy Notice do not apply to these third-party websites or their content, or to any collection of your data after you click on links to such third-party websites. We encourage you to read the privacy policies of every website you visit. Any links to third-party websites or locations are for your convenience and do not signify our endorsement of such third parties or their products, content, or websites.
      </p>

      <p>
        Third-Party Wallets
      </p>

      <p>
        To use our services or interact with us, you may use a third-party wallet which allows you to engage in transactions on public blockchains. Your interactions with any third-party wallet provider are governed by the applicable terms of service and privacy policy of that third party.
      </p>

      <p>
        Service Providers
      </p>

      <p>
        We may share your information with service providers and business partners around the world with whom we collaborate to fulfill the above purposes (e.g. IT provider, shipping companies, advertising service provider, security companies, banks, insurance companies, telecommunication companies, credit information agencies, address verification provider, lawyers) or who we engage to process personal data for any of the purposes listed above on our behalf and in accordance with our instructions only.
      </p>

      <p>
        Contractual Partners Including Customers
      </p>

      <p>
        In case required under the respective contract we share your data with other contractual partners. If we sell or buy any business or assets, we may disclose your data to the prospective seller or buyer of such business or assets to whom we assign or novate any of our rights and obligations.
      </p>

      <p>
        Legal Authorities
      </p>

      <p>
        If legally obliged or entitled to make disclosures or if it appears necessary to protect our interests, we may disclose your data to courts, law enforcement authorities, regulators, government officials or other legal authorities in Switzerland or abroad, e.g. in criminal investigations and legal proceedings including alternative dispute resolution as well as to prevent and combat money laundering and terrorist financing (e.g. duties in the event of a suspicion of money laundering, duty to report to Money Laundering Reporting Offices Switzerland or abroad) or due to further reporting duties.
      </p>

      <p>
        Our Appearance on Social Networks
      </p>

      <p>
        We are present on the following social media platforms: [e.g., X (previously Twitter), Farcaster, Discord, Telegram, LinkedIn, Instagram, YouTube, Mirror, BlueSky, Meetup]. We receive this data from you and the platforms, when you enter into contact with us via our online presence (e.g., when you communicate with us, comment on our content or visit our presence).
      </p>

      <p>
        At the same time, the platforms evaluate your use of our online presence and link this data with other data about you known to the platforms (e.g. about your behaviour and preferences). They also process this data for their own purposes under their own responsibility, in particular for marketing and market research purposes (e.g., to personalize advertising) and to control their platform (e.g., which content they show to you). This also happens, if you do not have a profile on the social media platform.
      </p>

      <p>
        We would like to point out that you use our presence on social media platforms and their functions on your own responsibility. This applies, in particular to the use of interactive functions (e.g. commenting, sharing, rating).
      </p>

      <p>
        The individual data processing activities and their scope differ depending on the social media provider. For details about the collection and storage of your personal data and about the type, scope and purpose of its use by the social media provider, please refer to the privacy policy of the respective social media provider.
      </p>

      <p>
        The data collected about you in this context is processed by the platforms and may be transferred to countries outside the European Union, in particular the USA, which is considered not to provide adequate data protection from a Swiss/EU perspective. Therefore, we have agreed to so-called standard data protection clauses (SCCs) with the social media providers in order to ensure compliance with an appropriate level of data protection in third countries.
      </p>

      <p>
        We do not know how the social media platforms use the data from your visit to our account and interaction with our posts for their own purposes, how long this data is stored and whether data is passed on to third parties. The data processing may differ depending on whether you are registered and logged in to the social network or visit the site as a non-registered and/or non-logged-in user. When you access a post or the account, the IP address assigned to your terminal device is transmitted to the provider of the social media platform. If you are currently logged in as a user, a cookie on your end device can be used to track how you have moved around the network. Buttons embedded in websites enable the platforms to record your visits to these website pages and assign them to your respective profile. Based on this data, content or advertising can be offered tailored to you. If you wish to avoid this, you should log out or deactivate the "stay logged in" function, delete the cookies on your device and restart your browser.
      </p>

      <p>
        To exercise your data subject rights, you can contact the provider of the social media platform. Please contact the provider of the social media platform directly for questions about profiling, processing of your data when using the website. For questions about the processing of your interaction with us on our site, write to the contact details provided by us above.
      </p>

      <p>
        For further information on the purpose and scope of data collection and processing by the social media platform, please review the privacy notices of these social media platforms, where you will also receive further information about your rights and about setting options for protecting your privacy.
      </p>

      <h4>
        8. Transfer of Data Abroad
      </h4>

      <p>
        We disclose data to other parties, not all of them located in Switzerland. Your data may be processed in the European Economic Area (EEA) and in exceptional circumstances also in countries outside the EEA and around the world, which includes countries that do not provide the same level of data protection as Switzerland or the EEA and are not recognized as providing an adequate level of data protection. We only transfer data to these countries when it is necessary for the performance of a contract or for the exercise or defence of legal claims, or if such transfer is based on your explicit consent or subject to safeguards that assure the protection of your data, such as the European Commission approved standard contractual clauses.
      </p>

      <h4>
        9. How Long We Keep your Personal Data
      </h4>

      <p>
        We only process your data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of complying with legal retention requirements and where required to assert or defend against legal claims, until the end of the relevant retention period or until the claims in question have been settled. Upon expiry of the applicable retention period, we will securely destroy your data in accordance with applicable laws and regulations.
      </p>

      <p>
        10. Security of your Personal Data
      </p>

      <p>
        We take appropriate organizational and technical security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.
      </p>

      <p>
        However, we and your personal data can still become victims of cyber-attacks, cybercrime, brute force, hacker attacks and further fraudulent and malicious activity including but not limited to viruses, forgeries, malfunctions, and interruptions which is out of our control and responsibility.
      </p>

      <p>
        We have also put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
      </p>

      <h4>
        11. Your Rights
      </h4>

      <p>
        You have various rights in relation with our processing of your personal data, depending on the applicable data protection law:
      </p>

      <p>
        Right of Access
      </p>

      <p>
        You have the right to request a copy of the personal data that we hold about you. There are exceptions to this right, so that access may be denied if, for example, making the information available to you would reveal personal data about another person, or if we are legally prevented from disclosing such information.
      </p>

      <p>
        Right to Rectification
      </p>

      <p>
        We aim to keep your personal data accurate, current, and complete. We encourage you to contact us to let us know if any of your personal data is not accurate or changes, so that we can keep your personal data up to date.
      </p>

      <p>
        Right to Erasure
      </p>

      <p>
        You have the right to require us to erase your personal data when the personal data is no longer necessary for the purposes for which it was collected, or when, among other things, your personal data have been unlawfully processed.
      </p>

      <p>
        If you are residing in the European Union, you also have the right to complain to your local data protection supervisory authority. You can find some contact details of the respective authorities of the Member States of the European Union here:
        <a
          href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
          target="_blank"
          class="link"
          rel="noopener,noreferrer">https://edpb.europa.eu/about-edpb/about-edpb/members_en</a>
      </p>

      <h4>
        12. Right to Object
      </h4>

      <p>
        Under applicable data protection law, you have the right to object at any time to the processing of personal data pertaining to you under certain circumstances, in particular where your data is processed in the public interest, on the basis of a balance of interests or for direct marketing purposes.
      </p>

      <p>
        If you like to exercise the above-mentioned rights, please contact us at contact@pwn.xyz. Please note that we need to identify you to prevent misuse, e.g., by means of a copy of your ID card or passport, unless identification is possible otherwise.
      </p>

      <h4>
        13. Newsletter
      </h4>

      <p>
        If you subscribe to one of our newsletters offered, you may cancel the subscription at any time by using the option to unsubscribe contained in the newsletter.
      </p>

      <h4>
        14. Cookies
      </h4>

      <p>
        We use cookies on our website and may allow certain third parties to do so as well. Cookies are small files that your browser automatically creates and that are stored on your device (laptop, tablet, smartphone, etc.) when you visit our website. For further information please see also our Cookie Policy.
      </p>

      <h4>
        15. Tracking Tools
      </h4>

      <p>
        Based on your consent we use tracking tools to ensure a tailored design and the continuous optimization of our website. We also use the following tracking tools to statistically record the use of our website and evaluate it for the purpose of optimizing the content we show you:
      </p>

      <p>
        Google Analytics:
      </p>

      <p>
        Google Ireland (based in Ireland) is our provider of “Google Analytics” and acts as our processor. Google Ireland relies on Google LLLC (based in the USA) as a processor for its services (both “Google”). Google uses cookies to track the behaviour of visitors to our website (duration, frequency of pages viewed, geographic origin of access, etc.) and compiles reports for us on the use of our website on this basis. Information on the data protection of Google Analytics can be found here <a
          href="https://support.google.com/analytics/answer/6004245"
          class="link"
          target="_blank"
          rel="noopener,noreferrer">https://support.google.com/analytics/answer/6004245</a> and if you have a Google account, you can find further details on processing by Google here <a
            href="https://policies.google.com/technologies/partner-sites?hl=de"
            class="link"
            target="_blank"
            rel="noopener,noreferrer">https://policies.google.com/technologies/partner-sites?hl=de</a>.
      </p>

      <p>
        Hotjar:
      </p>

      <p>
        We use Hotjar in order to better understand our users’ needs and to optimize our service and experience. Hotjar is a technology service that helps us better understand our users’ experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices. This includes a device's IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only). Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf.
      </p>

      <p>
        For further details, please see the ‘about Hotjar’ section of <a
          href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar"
          target="_blank"
          class="link"
          rel="noopener,noreferrer">Hotjar’s support site</a>
      </p>

      <h4>
        16. Updating and changing this Privacy Notice
      </h4>

      <p>
        Due to continuous development of our website, changes in law or regulatory requirements, we might need to change this Privacy Notice from time to time. Our current privacy Notice can be found at our website (pwn.xyz).
      </p>

      <p>
        In case of any questions or inquiries, please contact us at <a
          class="link"
          href="mailto:legal@pwn.xyz"
          target="_blank">legal@pwn.xyz</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
}
</script>

<style scoped>
.container {
  min-width: 100%;
  justify-content: center;
  display: flex;
}

.title {
  font-family: var(--font-family-autoscape);
}

.content {
  padding: var(--s3);
  color: var(--white);
  max-width: 900px;
  font-family: var(--font-family-oxygen-mono);
}

p {
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 21px !important;
  font-style: normal;
  font-weight: 400;
  margin: 3.9rem 0 1rem !important;
  font-family: var(--font-family-autoscape);
}

.link-dropdown {
    width: auto;
    display: inline-block;
    margin-left: auto;
    text-align: right;
}
</style>
