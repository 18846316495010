import { ref } from 'vue'

export type PwnMember = {
  name: string
  profileImg?: {
    url: string
    fileName: string
  }
  role: string
  focus?: string
  twitterLink?: string
  youtubeLink?: string
  ENS?: string
}

const pwnTeamMembers = ref<PwnMember[]>()
export default function usePwnTeamMembers() {
  const fetchPwnTeamMembers = async () => {
    const query = `{
      teamMemberCollection(order: [orderInView_ASC]) {
        items {
          name
          profileImg {
            sys {
              publishedAt
            }
            fileName
            url
          }
          role
          focus
          twitterLink
          youtubeLink
        }
      }
    }`

    const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${
      process.env.VUE_APP_CONTENTFUL_SPACE_ID
    }`

    const fetchOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
      }),
    }

    try {
      const response = await fetch(fetchUrl, fetchOptions)
      const JSONResponse = await response.json()

      pwnTeamMembers.value = JSONResponse.data.teamMemberCollection.items
    } catch (error) {
      throw new Error('Could not receive the data from Contentful!')
    }
  }

  return {
    pwnTeamMembers,
    fetchPwnTeamMembers,
  }
}
