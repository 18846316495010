<template>
  <div
    class="header-wrap"
    :class="{ headerAfterSplash: isAfterSplash }">
    <TheTopBanner/>
    <div class="header-row">
      <div class="logo-and-links">
        <a href="/#app">
          <div class="pwn-logo">
            <img
              width="88"
              height="28"
              src="/PWN_logo.svg">
          </div>
        </a>
        <!-- <a
          href="/#about"
          class="link local-link">About</a>
        <a
          href="/#howItWorks"
          class="link local-link">How it works</a>
        <a
          href="/#team"
          class="link local-link">Team</a>
        <a
          href="/events"
          class="link local-link">Events</a>
        <a
          target="_blank"
          :href="linkTo.blog"
          class="link local-link">Blog</a> -->
      </div>
      <div class="header-right-buttons">
        <!-- <DiscordBtn/> -->
        <LaunchBtn/>
      </div>
    </div>

    <div class="header-mobile">
      <a href="/#app">
        <div class="pwn-logo-mobile">
          <img
            width="88"
            height="28"
            src="/PWN_logo.svg">
        </div>
      </a>
      <div
        class="burger-menu"
        @click="isOpenMobileMenu = true">
        <img
          width="31"
          height="16"
          src="/burger.svg">
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="isOpenMobileMenu"
        class="mobile-menu">
        <div class="header-mobile">
          <a
            href="/#app"
            @click="isOpenMobileMenu = false">
            <div class="pwn-logo-mobile">
              <img
                width="88"
                height="28"
                src="/PWN_logo.svg">
            </div>
          </a>
          <div
            class="burger-menu"
            @click="isOpenMobileMenu = false">
            <img
              width="22"
              height="22"
              src="/cross.svg">
          </div>
        </div>

        <div class="mobile-links">
          <!-- <a
            href="/#about"
            class="link"
            @click="isOpenMobileMenu = false">About</a> -->
          <a
            href="/#howItWorks"
            class="link"
            @click="isOpenMobileMenu = false">How it works</a>
          <!-- <a
            href="/#team"
            class="link"
            @click="isOpenMobileMenu = false">Team</a> -->
          <!-- <a
            href="/events"
            class="link"
            @click="isOpenMobileMenu = false">Events</a> -->
        </div>
        <div class="mobile-launch-btn">
          <LaunchBtn/>
        </div>

        <div class="soc-links">
          <a
            :href="linkTo.twitter"
            target="_blank">
            <img
              width="30"
              height="30"
              src="/twitter.svg">
          </a>
          <a
            class="soc-link-right"
            :href="linkTo.discord"
            target="_blank">
            <img
              width="35"
              height="27"
              src="/discord.svg">
          </a>
        </div>

        <div class="bg-mobile-menu">
          <img src="/bg_1.png">
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { linkTo } from '@/constants'
import LaunchBtn from '@/components/Buttons/LaunchBtn.vue'
// import DiscordBtn from '@/components/Buttons/DiscordBtn.vue'
import TheTopBanner from '@/components/Banners/TheTopBanner.vue'

export default {
  name: 'Header',
  components: { LaunchBtn, TheTopBanner },
  data() {
    return {
      isAfterSplash: false,
      isOpenMobileMenu: false,
      linkTo,
    }
  },
  computed: {
    collectionSelected() {
      return this.$route.name === 'Collection'
    },
  },

  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  unmounted: function () {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
      if (window.innerHeight < window.scrollY) {
        this.isAfterSplash = true
      } else {
        this.isAfterSplash = false
      }
    },
  },
}
</script>

<style scoped>
/*/////// Desktop ///////*/
.header-wrap {
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
}

.headerAfterSplash {
  border-bottom: 1px solid rgb(255 255 255 / 5%);
  background-color: var(--col-primary-bg);
  width: 100%;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 104px;
  margin: 0 128px;
}

.logo-and-links {
  display: flex;
}

.pwn-logo {
  margin-right: 98px;
}

.local-link {
  font-size: 1vw;
  margin-right: 36px;
  white-space: nowrap;
}

.header-mobile {
  display: none;
}

.collection-header:hover,
.collection-header-selected {
  background: linear-gradient(180deg, #00ffe0 25%, #42ff00 50%, #fff500 75%);
  background-size: 200% 200%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animation 2s linear infinite, rotate 3s linear infinite;

  --angle: 360deg;
  border-bottom: 1px dashed;
  border-image: linear-gradient(var(--angle), #00ffe0 30%, #42ff00 60%, #fff500 90%) 1;
}

@keyframes rotate {
  to {
    --angle: 0deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes animation {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 91% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}

@keyframes animation {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 91% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}

@keyframes animation {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 91% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}
/*/////// Mobile ///////*/
@media (max-width: 1100px) {
  .pwn-logo {
    display: none;
  }

  .mobile-menu {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--col-primary-bg);
  }

  .burger-menu {
    cursor: pointer;
  }

  .soc-links {
    margin-top: 96px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .soc-link-right {
    margin-left: 55px;
  }

  .bg-mobile-menu {
    position: absolute;
    top: 67px;
    left: -133px;
    z-index: -1;
    max-width: 895px;
    max-height: 680px;
  }

  .mobile-links {
    font-size: 16px;
    display: flex;
    justify-content: center;
    gap: 40px;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 10vh;
    margin-bottom: 73px;
  }

  .mobile-link-margin {
    margin: 40px;
  }

  .mobile-launch-btn {
    margin-top: 73px;
    margin-right: 25px;
    margin-left: 25px;
    display: flex;
    justify-content: center;
  }

  .header-row {
    display: none;
  }

  .header-mobile {
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 25px;
  }
}

.header-right-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}
</style>
