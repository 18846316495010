<template>
  <div class="section-wrap how-it-works">
    <div class="bg-right-side-mobile">
      <img src="/bg_1.png">
    </div>

    <div class="section section-ext">
      <div class="bg-right-side">
        <img src="/bg_1.png">
      </div>
      <div class="page-preview-img">
        <img srcset="/how_it_works.png, /how_it_works.png@2x.png 2x">
      </div>

      <div class="spacer"/>
      <div
        id="howItWorks"
        class="description scroll-anchor">
        <h3 class="description-title title">
          How it works
        </h3>

        <p class="description-text">
          PWN is the go-to hub for permissionless, peer-to-peer loans. Use any asset (ERC-20, NFTs,...), or a bundle of both as collateral, and invest in loans on your own terms, all without risk of price-based liquidation.
        </p>

        <LaunchBtn/>
      </div>
    </div>
  </div>
</template>

<script>
import LaunchBtn from '@/components/Buttons/LaunchBtn.vue'
export default {
  name: 'Header',
  components: { LaunchBtn },
}
</script>

<style scoped>
.how-it-works {
  margin-top: 0;
  z-index: 0;
}

.spacer {
  width: var(--s6);
}

.description-title {
  margin-bottom: 38px;
}

.description-text {
  width: 520px;
  margin-bottom: 40px;
  text-align: justify;
}

.page-preview-img img {
  margin-bottom: 30px;
  max-width: 573px;
  width: 100%;
}

.bg-right-side {
  position: absolute;
  top: 150px;
  right: 359px;
  z-index: -1;
  max-width: 895px;
  max-height: 680px;
}

.bg-right-side-mobile {
  display: none;
}

.section-ext {
  margin-top: var(--s8);
}

/*/////// Mobile ///////*/
@media (max-width: 1100px) {
  .section-ext {
    margin-top: var(--s3);
  }

  .bg-right-side {
    display: none;
  }

  .bg-right-side-mobile {
    display: block;
    position: absolute;
    top: 0;
    right: -300px;
    z-index: -1;
    max-width: 895px;
    max-height: 680px;
  }

  .how-it-works {
    overflow: hidden;
    margin-top: 0;
  }

  .description {
    margin: var(--s3) 0 var(--s3) 0;
  }

  .description-text {
    max-width: 580px;
    width: unset;
  }
}

@media (max-width: 1450px) {
  .description {
    margin-bottom: var(--s6);
  }

  .section-ext {
    flex-direction: column-reverse;
  }
}
</style>
