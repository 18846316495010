import App from './App.vue'
import { createApp } from 'vue'
import router from './router/index'
import { Plugin } from 'vue-responsive-video-background-player'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import '@/styles/global.css'

const app = createApp(App).use(router)
app.use(Plugin)
app.use(FloatingVue, {
  themes: {
    'info-tooltip': {
      $extend: 'tooltip',
    },
  },
})
app.mount('#app')
