<template>
  <div class="section section-margin">
    <div class="box">
      <h3 class="title-margin title">
        Lender Benefits
      </h3>
      <div class="description">
        <div class="left-info">
          <div
            class="tab-title"
            :class="{ activeTabLTitle: activeTabL === 'higher-returns' }"
            @mouseover="activeTabL = 'higher-returns'">
            High APY. Low risk
          </div>
          <div class="wrap-tab-titles">
            <div
              class="tab-title"
              :class="{ activeTabLTitle: activeTabL === 'customize' }"
              @mouseover="activeTabL = 'customize'">
              Custom investment<br> strategies
            </div>
            <div
              class="tab-title"
              :class="{ activeTabLTitle: activeTabL === 'keep' }"
              @mouseover="activeTabL = 'keep'">
              Collateral ownership<br> transfer
            </div>
            <div
              class="tab-title"
              :class="{ activeTabLTitle: activeTabL === 'loan' }"
              @mouseover="activeTabL = 'loan'">
              LOAN Token
            </div>
          </div>
          <!-- <div class="dwnl-btn-desktop">
            <LaunchBtn/>
          </div> -->
        </div>

        <div class="right-info-lender">
          <div class="right-column-icon">
            <img
              v-show="activeTabL === 'higher-returns'"
              height="89"
              src="/borrowerLender/higher-returns.svg">
            <img
              v-show="activeTabL === 'customize'"
              height="89"
              src="/borrowerLender/investment-strategy.svg">
            <img
              v-show="activeTabL === 'keep'"
              height="89"
              src="/borrowerLender/collateral.svg">
            <img
              v-show="activeTabL === 'loan'"
              height="89"
              src="/borrowerLender/deed-token.svg">
          </div>
          <div class="tab-title-mob">
            High APY. Low risk
          </div>

          <p
            class="right-info-lender-column"
            :class="{ activeTabL: activeTabL === 'higher-returns' }">
            With peer-to-peer loans, you can enjoy high, predictable APY without the need for intricate DeFi strategies.
          </p>

          <div class="tab-title-mob">
            Custom investment strategies
          </div>
          <p
            class="right-info-lender-column"
            :class="{ activeTabL: activeTabL === 'customize' }">
            You can pick the loan request (or create a loan offer) that best fits your investment strategy, timeline, and risk profile.
          </p>
          <div class="tab-title-mob">
            Collateral ownership transfer
          </div>
          <p
            class="right-info-lender-column"
            :class="{ activeTabL: activeTabL === 'keep' }">
            Every active loan on PWN is represented by a LOAN NFT. You’re free to sell it, or use it as collateral to leverage yourself for more advanced strategies.
          </p>

          <div class="tab-title-mob">
            LOAN Token
          </div>
          <p
            class="right-info-lender-column"
            :class="{ activeTabL: activeTabL === 'loan' }">
            The LOAN token is an NFT representation of an active loan on PWN. It gives the lender the ability to sell the loan or use it as collateral for further borrowing. The LOAN token is the cornerstone of our upcoming advanced DeFi products.
          </p>
        </div>

        <!-- <div class="dwnl-btn-mobile">
          <LaunchBtn/>
        </div> -->
      </div>
    </div>

    <div class="transfer-img">
      <img
        width="110"
        height="110"
        src="/transfer.svg">
    </div>

    <div class="box top-box">
      <h3 class="title-margin title">
        Borrower Benefits
      </h3>
      <div class="description">
        <div class="left-info">
          <div
            class="tab-title"
            :class="{ activeTabBTitle: activeTabB === 'any-token' }"
            @mouseover="activeTabB = 'any-token'">
            Anything as collateral
          </div>
          <div class="wrap-tab-titles">
            <div
              class="tab-title"
              :class="{ activeTabBTitle: activeTabB === 'choose-loan' }"
              @mouseover="activeTabB = 'choose-loan'">
              Configurable loan terms
            </div>
            <div
              class="tab-title"
              :class="{ activeTabBTitle: activeTabB === 'no-risk' }"
              @mouseover="activeTabB = 'no-risk'">
              Zero price-based<br> liquidation
            </div>
          </div>
          <!-- <div class="dwnl-btn-desktop">
            <LaunchBtn/>
          </div> -->
        </div>

        <div
          class="right-info"
          :class="{
            anyTokenImg: activeTabB === 'any-token',
            noRiskImg: activeTabB === 'no-risk'
          }">
          <div class="right-column-icon">
            <img
              v-show="activeTabB === 'any-token'"
              height="120"
              src="/borrowerLender/token-graphic.svg">
            <img
              v-show="activeTabB === 'choose-loan'"
              height="81"
              src="/borrowerLender/loan-terms.svg">
            <img
              v-show="activeTabB === 'no-risk'"
              height="89"
              src="/borrowerLender/no-liquidation.svg">
          </div>
          <div class="tab-title-mob">
            Anything as collateral
          </div>
          <p
            class="right-description"
            :class="{ activeTabB: activeTabB === 'any-token' }">
            Any standard ERC token can be used as collateral. Coins, NFTs, and even a mix of both…if you own it, you can pwn it.
          </p>
          <div class="tab-title-mob">
            Configurable loan terms
          </div>
          <p
            class="right-description"
            :class="{ activeTabB: activeTabB === 'choose-loan' }">
            You’re free to set your own loan terms (amount, duration, interest,...) or to browse through existing loan offers for your assets.
          </p>
          <div class="tab-title-mob">
            Zero price-based liquidation
          </div>
          <p
            class="right-description"
            :class="{ activeTabB: activeTabB === 'no-risk' }">
            You and your lender agree on terms for the entire loan duration. As long as you pay back your loan before it expires, you don’t have to worry about asset price fluctuation and/or auto-liquidation.
          </p>
        </div>

        <!-- <div class="dwnl-btn-mobile">
          <LaunchBtn/>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import LaunchBtn from '@/components/Buttons/LaunchBtn.vue'

export default {
  name: 'BorrowLender',
  // components: { LaunchBtn },
  data() {
    return {
      activeTabB: 'any-token',
      activeTabL: 'higher-returns',
    }
  },
}
</script>

<style scoped>
.section-margin {
  margin-top: 128px;
  position: relative;
}

.right-info-lender-column {
  margin-top: 15px;
  color: rgb(255 255 255 / 70%);
  display: none;
  text-align: justify;
}

.right-description {
  color: rgb(255 255 255 / 70%);
  display: none;
  text-align: justify;
}

.activeTabB,
.activeTabL {
  display: block;
}

.transfer-img {
  display: flex;
  z-index: 10;
  width: 100%;
  margin: -36px 0;
}

.transfer-img img {
  z-index: 10;
  margin: auto;
}

.box {
  padding: 96px 111px 70px;
  max-width: 1297px;
  background: #131117;
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source:
    linear-gradient(
      180deg,
      rgb(255 255 255 / 12%) 0%,
      rgb(255 255 255 / 0%) 142.67%
    );
  border-radius: 5px;
}

.top-box {
  margin-bottom: -52px;
}

.description {
  display: flex;
  justify-content: space-between;
  min-height: 17rem;
}

.right-column-icon {
  margin-bottom: 12px;
}

.left-info {
  min-width: 325px;
  margin-right: 119px;
}

.right-info,
.right-info-lender {
  margin-top: -32px;
  text-align: justify;
}

.right-info-title {
  margin-bottom: 33px;
  margin-top: 0;
  font-size: 30px;
}

.title-margin {
  margin: 0 0 82px;
}

.dwnl-btn-mobile {
  display: none;
  margin-top: 51px;
}

.dwnl-btn-desktop {
  margin-top: 3rem;
}

.wrap-tab-titles {
  display: flex;
  flex-direction: column;
}

.tab-title {
  margin-bottom: 14px;
  font-size: 20px;
  color: rgb(255 255 255 / 100%);
  border-bottom: 1px dashed transparent;
  cursor: default;
}

.activeTabBTitle,
.activeTabLTitle {
  color: var(--col-text-accent-light);
  width: fit-content;
  border-bottom: 1px dashed #00ffe0;
  transition: all 0.3s;
}

.anyTokenImg {
  margin-top: -70px;
}

.noRiskImg {
  margin-top: -39px;
}

.tab-title-mob {
  display: none;
}

/*/////// Mobile ///////*/

@media (max-width: 1100px) {
  .right-info {
    margin-top: 0;
    min-height: 440px;
    text-align: left;
  }

  .left-info {
    display: none;
  }

  .right-info-lender {
    margin-top: 0;
    min-height: 340px;
  }

  .right-description {
    margin-top: 25px;
    margin-bottom: 0;
    display: block;
  }

  .tab-title {
    margin-bottom: 43px;
    border-bottom: none;
  }

  .tab-title-mob {
    display: block;
    margin-top: 65px;
    font-size: 20px;
    color: rgb(255 255 255 / 100%);
  }

  .box {
    padding: var(--s6) var(--s1);
  }

  .green-with-dashes {
    font-size: 18px;
  }

  .title-margin {
    margin-bottom: 5px;
  }

  .left-info {
    min-width: unset;
    margin-right: unset;
  }

  .right-column-icon {
    display: none;
  }

  .section-margin {
    margin-top: 75px;
    margin-left: 0;
    margin-right: 0;
  }

  .dwnl-btn-mobile {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .dwnl-btn-desktop {
    display: none;
  }

  .description {
    flex-wrap: wrap;
  }

  .whitepaper-download {
    display: none;
  }

  .right-description {
    font-size: 17px;
    text-align: left;
  }

  .right-info-lender-column {
    display: block;
    font-size: 17px;
    margin-top: 25px;
    margin-bottom: 0;
    text-align: left;
  }

  .activeTabBTitle,
  .activeTabLTitle {
    display: none;
  }
}
</style>
