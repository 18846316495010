<template>
  <div>
    <Header class="header-add-background"/>
    <main class="container">
      <div class="pwn-logo">
        <img
          width="246"
          height="80"
          src="/PWN_logo.svg"
          alt="PWN Logo">
      </div>
      <div class="title">
        <span class="accent-text">PWN</span> is live in closed beta
      </div>
      <div class="content">
        You need an access key (POAP NFT) to enter the platform. Connect your wallet to join the waitlist.
        We drop access POAPs to some people on the waitlist on a regular basis.
      </div>
      <div
        v-if="!isWalletConnected"
        class="connect-wallet-container">
        <div
          class="connect-wallet-button primary-btn"
          @click="connectWalletButtonAction">
          {{ connectWalletButtonText }}
        </div>
      </div>
      <template v-else-if="isWalletConnected">
        <div class="connected-address">
          Your wallet: {{ userAddressShort }}
        </div>
        <div class="connected-main">
          <Loader v-if="isLoading"/>
          <div
            v-else-if="hasAccessPoap"
            class="has-access-poap-section">
            <p class="access-poap-owner-text">
              You already own an access POAP
            </p>
            <div class="access-poap-logo-container">
              <img
                src="@/assets/poap.png"
                alt="Access POAP logo"
                class="access-poap-logo">
            </div>
            <EnterPwnBtn class="enter-pwn-btn"/>
          </div>
          <div v-else-if="hasJoinedWaitlist">
            <div class="waitlist-points">
              Points per minute: 1
            </div>
            <div class="waitlist-points">
              Points accumulated: {{ userPoints }}
            </div>
            <div class="waitlist-position">
              Waitlist position: {{ userRank }}
            </div>
          </div>
          <div
            v-else-if="!hasJoinedWaitlist"
            class="join-waitlist-button primary-btn"
            @click="joinWaitlist">
            Join the waitlist
          </div>
        </div>
        <div
          class="disconnect-wallet-button negative-btn"
          @click="disconnectWallet">
          Disconnect wallet
        </div>
      </template>
    </main>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import MetaMaskOnboarding from '@metamask/onboarding'
import EnterPwnBtn from './components/Buttons/EnterPwnBtn.vue'
import { autoConnectWallet, connectWallet, hasEthereumWalletInstalled } from './web3'
import { fetchHasPoap, fetchUserWaitlistAccount, joinWaitlist } from './waitlist'
import Loader from './components/Loader.vue'

export default {
  name: 'Waitlist',
  components: { EnterPwnBtn, Header, Loader },
  data() {
    return {
      userAddress: null,
      userPoints: null,
      userRank: null,
      hasAccessPoap: false,
      isLoading: false,
    }
  },
  computed: {
    isWalletConnected() { return this.userAddress },
    hasJoinedWaitlist() { return this.userPoints },
    connectWalletButtonText() { return hasEthereumWalletInstalled() ? 'Connect wallet' : 'Install Metamask' },
    userAddressShort() { return `${this.userAddress.slice(0, 5)}...${this.userAddress.slice(39, 42)}` },
  },
  async mounted() {
    this.userAddress = await autoConnectWallet()
    if (this.userAddress) await this.fetchUserWaitlistDetails()
  },
  methods: {
    async joinWaitlist() {
      this.isLoading = true;
      ({ userRank: this.userRank, userPoints: this.userPoints, hasAccessPoap: this.hasAccessPoap } = await joinWaitlist(this.userAddress))
      this.isLoading = false
    },
    async connectWalletButtonAction() {
      if (hasEthereumWalletInstalled()) {
        this.isLoading = true
        this.userAddress = await connectWallet()
        if (this.userAddress) await this.fetchUserWaitlistDetails()
        this.isLoading = false
      } else {
        const onboardingPackage = new MetaMaskOnboarding()
        onboardingPackage.startOnboarding()
      }
    },
    async fetchUserWaitlistDetails() {
      this.isLoading = true
      this.hasAccessPoap = await fetchHasPoap(this.userAddress)
      if (!this.hasAccessPoap) {
        ({ userRank: this.userRank, userPoints: this.userPoints, hasAccessPoap: this.hasAccessPoap } = await fetchUserWaitlistAccount(this.userAddress))
      }
      this.isLoading = false
    },
    disconnectWallet() {
      this.userAddress = null
    },
  },
}
</script>

<style scoped>
.header-add-background {
  background-color: #100d13;
}

.container {
  align-items: center;
  min-width: 100%;
  margin-top: 239px;
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
}

.content {
  color: var(--white);
  max-width: 490px;
  margin: 30px 15px 60px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

.pwn-logo {
  filter: drop-shadow(0 0 50px rgb(0 255 224 / 20%));
}

.title {
  height: 64px;
  margin: 46px 10px 0 15px;
  font-family: var(--font-family-autoscape);
  font-size: 24px;
  font-weight: 550;
  line-height: 32px;
  letter-spacing: 0;
  text-align: center;
}

.accent-text {
  color: var(--col-text-accent-light);
}

.connect-wallet-button {
  background-color: #00ffe040;
  color: #00ffe0;
  border: 0;
  border-radius: 0;
  font-family: var(--font-family-oxygen-mono);
}

.connect-wallet-button:hover {
  background: rgb(0 255 224 / 60%);
}

.connected-address {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 432px;
  height: 75px;
  background-image: var(--border-white-dashed);
  margin-bottom: 28px;
}

.connected-main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 432px;
  padding: 1.5rem 0;
  background-image: var(--border-white-dashed);
  margin-bottom: 64px;
}

.waitlist-points {
  margin-bottom: 1rem;
}

.waitlist-position {
  margin-bottom: 0;
}

.has-access-poap-section {
  text-align: center;
}

.access-poap-owner-text {
  color: #00ffe0;
  /* font-size: 1rem; */
  margin: 0;
}

.access-poap-logo-container {
  width: 86px;
  height: 86px;
  margin: 32px auto;
}

.access-poap-logo {
  width: 100%;
  height: 100%;
  object-fit: none;
}

.enter-pwn-btn {
  margin-bottom: 16px;
}
</style>
